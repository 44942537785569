<template>
  <a-drawer
    title="新增/编辑供应商"
    width="700"
    :visible="visible"
    :closable="false"
    :footer-style="{ textAlign: 'right' }"
    @close="onClose"
  >
    <!-- from -->
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 供应商名称 -->
      <a-form-item
        label="供应商名称"
        name="name"
        :rules="[{ required: true }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.name"
          placeholder="建议输入公司全称"
        />
      </a-form-item>
      <!-- 联系人 -->
      <a-form-item
        label="联系人"
        name="contact"
        :rules="[{ required: true }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.contact"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 手机号码 -->
      <a-form-item
        label="手机号码"
        name="phone"
        :rules="[{ required: true, validator: validatorPhone }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.phone"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 联系地址 -->
      <a-form-item
        label="联系地址"
        name="addr"
        :rules="[{ required: true }]"
      >
        <a-input
          class="form-input"
          v-model:value="formState.addr"
          placeholder="请输入"
        />
      </a-form-item>
      <!-- 独家授权 -->
      <a-form-item
        label="合作状态"
        name="cooperate"
      >
        <a-switch v-model:checked="formState.cooperate" checked-children="开" un-checked-children="关" />
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { nextTick } from 'process'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import { supplierSave, supplierDetail } from '@/api/copyright'
import { message } from 'ant-design-vue'

// props
const props = defineProps({
  type: Number
})
// emit
const emit = defineEmits(['success'])
// from
const RefForm = ref(null)
// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 供应商信息
let supplierJson = ref(null)
// 表单参数
const formState = ref({
  // 供应商名称
  name: undefined,
  // 联系人
  contact: undefined,
  // 手机号码
  phone: undefined,
  // 联系地址
  addr: undefined,
  // 合作状态
  cooperate: true
})

// 显示
function showDrawer (data) {
  // 记录
  supplierJson.value = data
  // 显示
  visible.value = true
  // 延迟处理
  nextTick(() => {
    // 重置
    resetFields()
    // 获取详情数据
    if (data) { getSupplierDetail() }
  })
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    visible.value = false
  }
}

// 重置
function resetFields () {
  RefForm.value.resetFields()
}

// 获取详情数据
function getSupplierDetail () {
    isLoading.value = true
    const params = {
      type: props.type,
      id: supplierJson.value.id
    }
    supplierDetail(params).then(res => {
      isLoading.value = false
      const { code, data } = res
      if (code === 0) {
        formState.value = data
        formState.value.cooperate = Boolean(data.friendship_status)
      }
    }).catch(() => {
      isLoading.value = false
    })
}

// 提交
function touchSubmit () {
  RefForm.value.validate().then((res => {
    // 新增
    isLoading.value = true
    const params = {
      type: props.type,
      id: supplierJson.value && supplierJson.value.id,
      ...res,
      friendship_status: Number(res.cooperate)
    }
    supplierSave(params).then(res => {
      isLoading.value = false
      const { code } = res
      if (code === 0) {
        emit('success')
        onClose()
        if (supplierJson.value) {
          message.success('编辑成功')
        } else {
          message.success('提交成功')
        }
      } else {
        message.error(res.message)
      }
    }).catch(() => {
      isLoading.value = false
      if (supplierJson.value) {
        message.success('编辑失败')
      } else {
        message.success('提交失败')
      }
    })
  })).catch((err) => {
    console.log(err)
  })
}

// 效验 - 手机号
function validatorPhone (rule, value) {
  if (!value) {
    return Promise.reject('请输入${label}')
  } else if (!Pub.REG_IS_PHONE(value)) {
    return Promise.reject('请输入正确的手机号')
  }
  return Promise.resolve()
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
</style>